/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
* LAYOUT
*/
.breadcrumb-nav__list {
  margin: 0 0 3em;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.breadcrumb-nav__item:not(:last-child)::after {
  content: ">";
  margin: 0 0.5em;
}
.breadcrumb-nav__link {
  text-decoration: underline;
  display: inline-block;
}
.breadcrumb-nav__link[aria-current] {
  pointer-events: none;
  text-decoration: none;
  font-weight: bold;
}

.sitemap__list {
  margin: 0 0 3em;
  padding: 0;
  list-style-type: none;
  font-size: 1.125rem;
}
.sitemap__list .sitemap__list {
  font-size: 1rem;
  padding-left: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0;
}
.sitemap__item {
  margin: 0 0 0.5em;
}
.sitemap__link, .sitemap__link:link, .sitemap__link:visited {
  text-decoration: underline;
}
.sitemap__link:hover, .sitemap__link:focus, .sitemap__link:active {
  text-decoration: none;
}

.login-links {
  margin: 0 0 2rem;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.login-links__item {
  margin: 5px 15px;
}
.page {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.page-module {
  margin-bottom: 3em;
}

.common-page {
  padding-top: 75px;
  padding-bottom: 75px;
}

.travel {
  display: grid;
  counter-increment: sections;
  margin-bottom: clamp(40px, 4vw, 80px);
  grid-gap: 40px;
  gap: 40px;
}
.travel__section {
  padding-left: 5rem;
}
.travel__means {
  display: none;
  align-items: center;
  gap: 25px;
  transform: translate(-4rem, 8%);
}
@media screen and (min-width: 1300px) {
  .travel__means {
    display: inline-flex;
  }
}
@media screen and (min-width: 1600px) {
  .travel__text {
    transform: translateX(-5rem);
  }
}
.travel__text .travel__top {
  margin-bottom: 2.125rem;
  padding-bottom: 2.125rem;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.travel__text .travel__top::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 150px;
  height: 4px;
  background-color: #b16300;
}
.travel__text .travel__heading {
  position: relative;
  color: #b16300;
  font-family: "Jost", system-ui, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  transform: translateX(-5rem);
  margin: 0 1em 0 0;
}
@media screen and (min-width: 1300px) {
  .travel__text .travel__heading {
    white-space: nowrap;
  }
}
.travel__text .travel__heading::before {
  content: counter(sections, decimal-leading-zero);
  color: #cacaca;
  font-size: 3.125rem;
  font-family: "TiltWarp", Poppins, Montserrat, sans-serif;
  min-width: 5rem;
}
.travel__text .text-module {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5555555556;
}
.travel__text .text-module p {
  line-height: inherit;
}
.travel__text .text-module a,
.travel__text .text-module a:link,
.travel__text .text-module a:visited {
  font-weight: 500;
  color: #083735;
  text-decoration: none;
}
.travel__text .text-module a:hover,
.travel__text .text-module a:focus,
.travel__text .text-module a:active {
  text-decoration: underline;
}
@media screen and (min-width: 800px) {
  .travel:nth-child(odd) {
    grid-template-areas: "text image";
    grid-template-columns: minmax(min-content, 400px) 1fr;
  }
  .travel:nth-child(even) {
    grid-template-areas: "image text";
    grid-template-columns: 1fr minmax(min-content, 400px);
  }
  .travel__text {
    grid-area: text;
  }
  .travel__flair {
    grid-area: image;
  }
}
@media screen and (min-width: 1024px) {
  .travel:nth-child(odd) {
    grid-template-columns: minmax(min-content, 555px) 1fr;
  }
  .travel:nth-child(even) {
    grid-template-columns: 1fr minmax(min-content, 555px);
  }
}
.travel__flair {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 800px) {
  .travel__flair::before {
    content: "";
    display: block;
    aspect-ratio: 430/466;
    width: 100%;
    max-width: 320px;
    grid-column: 1;
    grid-row: 1;
    background: transparent url(/assets/images/travel-frame.svg) no-repeat 0 0/100% 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 800px) and (min-width: 1422px) {
  .travel__flair::before {
    max-width: 430px;
  }
}
@media screen and (min-width: 1600px) {
  .travel--first .travel__flair::before {
    margin-left: 144px;
  }
}
@media screen and (min-width: 1600px) {
  .travel--second .travel__flair::before {
    margin-left: 44px;
  }
}
@media screen and (min-width: 1600px) {
  .travel--third .travel__flair::before {
    margin-left: 160px;
  }
}
.travel__picture {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1600px) {
  .travel__picture {
    display: inline;
  }
}
.travel__picture img {
  object-fit: cover;
  object-position: center;
  mask-size: 100% 100%;
  mask-position: 0 0;
  mask-repeat: no-repeat;
  display: block;
}
.travel--first .travel__picture img {
  mask-image: url(/assets/images/mask-travel-1.svg);
}
@media screen and (min-width: 1600px) {
  .travel--first .travel__picture img {
    transform: translate(8%, 7%);
  }
}
.travel--second .travel__picture img {
  mask-image: url(/assets/images/mask-travel-2.svg);
}
@media screen and (min-width: 1600px) {
  .travel--second .travel__picture img {
    transform: translate(0, -6%);
  }
}
.travel--third .travel__picture img {
  mask-image: url(/assets/images/mask-travel-3.svg);
}
@media screen and (min-width: 1600px) {
  .travel--third .travel__picture img {
    transform: translate(1%, 4%);
  }
}

.google-map {
  display: grid;
}
.google-map__iframe {
  max-width: 100%;
  width: 100%;
  height: 500px;
  border: 0;
}

.about-page {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
}
@media screen and (min-width: 800px) {
  .about-page {
    grid-template-columns: 1fr 400px;
  }
}
@media screen and (min-width: 1280px) {
  .about-page {
    grid-gap: 0 100px;
    gap: 0 100px;
    grid-template-columns: 1fr 560px;
  }
}
.about-page__heading {
  text-align: center;
  font-size: 2.125rem;
  margin-top: 0.36em;
  margin-bottom: 0;
}
.about-page__address {
  text-align: center;
  font-size: 1.3125rem;
  line-height: 1.4285714286;
  margin-bottom: 65px;
}
@media screen and (min-width: 800px) {
  .about-page__gallery {
    grid-column: 1;
    grid-row: 2;
  }
}
@media screen and (min-width: 800px) {
  .about-page__booking {
    grid-column: 2;
    grid-row: 1/span 2;
  }
}
.about-page__booking .booking {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 800px) {
  .about-page__text {
    grid-column: 1;
    grid-row: 1;
  }
}
.about-page__text .text-module {
  font-size: 1.125rem;
  font-weight: 300;
}
.about-page__text .text-module p {
  line-height: 1.5555555556;
}
.about-page__text .text-module a,
.about-page__text .text-module a:link,
.about-page__text .text-module a:visited {
  font-weight: 500;
  color: #083735;
  text-decoration: none;
}
.about-page__text .text-module a:hover,
.about-page__text .text-module a:focus,
.about-page__text .text-module a:active {
  text-decoration: underline;
}

.pricing-page {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
}
@media screen and (min-width: 800px) {
  .pricing-page {
    grid-template-columns: 350px 1fr;
  }
}
@media screen and (min-width: 1422px) {
  .pricing-page {
    grid-template-columns: 533px 1fr;
    grid-gap: 100px;
    gap: 100px;
  }
}
.pricing-page__cars .text-module p {
  line-height: 1.5555555556;
  font-weight: 300;
  font-size: 1.125rem;
}
.pricing-page__heading {
  text-align: left;
  font-size: 2.125rem;
  margin-top: 0.36em;
  margin-bottom: 0;
}
.pricing-page__car-cta {
  color: #083735;
  font-weight: 600;
  font-size: 1.3125rem;
  margin-top: 2em;
}
.pricing-page__text .text-module {
  font-size: 1.125rem;
  font-weight: 300;
}
.pricing-page__text .text-module p {
  line-height: 1.5555555556;
}
.pricing-page__text .text-module a,
.pricing-page__text .text-module a:link,
.pricing-page__text .text-module a:visited {
  font-weight: 500;
  color: #083735;
  text-decoration: none;
}
.pricing-page__text .text-module a:hover,
.pricing-page__text .text-module a:focus,
.pricing-page__text .text-module a:active {
  text-decoration: underline;
}

.cars {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 40px;
  gap: 40px;
}
.cars__image {
  aspect-ratio: 300/222;
  width: 100%;
  max-width: 300px;
  mask-image: url(/assets/images/car-mask.svg);
  mask-size: 100% 100%;
  mask-position: 0 0;
  mask-repeat: no-repeat;
  margin-bottom: 32px;
}
.cars__name {
  font-weight: bold;
  font-size: 2rem;
  color: #083735;
  margin: 0 0 0.1em;
}
.cars__performance {
  font-weight: bold;
  font-size: 1.5rem;
  color: #b16300;
  margin: 0 0 0.3em;
}
.cars__transmission {
  font-weight: 500;
  font-size: 1.1875rem;
  color: #7a7a7a;
  margin: 0;
}

.pricing {
  margin: 0 0 3.5rem;
  padding: 0;
  list-style-type: none;
}
.pricing__section {
  border-bottom: 4px #b16300 solid;
  padding-bottom: 3.5rem;
}
.pricing__section:not(:last-child) {
  margin-bottom: 3.5rem;
}
.pricing__price {
  color: #7a7a7a;
  font-size: 1.1875rem;
  font-weight: 500;
  margin: 0 0 1em;
}
.pricing__price strong {
  color: #083735;
  font-family: "TiltWarp", Poppins, Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
}
.pricing__type {
  font-weight: bold;
  font-size: 1.5rem;
  color: #b16300;
  margin: 0 0 0.5em;
}
.pricing__timetable {
  margin: 0;
}

.thank-you {
  text-align: center;
}
.thank-you .text-module {
  text-align: inherit;
}
.thank-you .text-module p {
  font-size: 1.25rem;
  text-align: inherit;
}
.thank-you .text-module a,
.thank-you .text-module a:link,
.thank-you .text-module a:visited {
  color: #083735;
  text-decoration: underline;
}
.thank-you .text-module a:hover,
.thank-you .text-module a:focus,
.thank-you .text-module a:active {
  text-decoration: none;
}