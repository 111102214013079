@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

/*
* LAYOUT
*/

@import "components/breadcrumb-nav", "components/sitemap", "components/lists";

.page {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.page-module {
	margin-bottom: 3em;
}

.common-page {
	padding-top: 75px;
	padding-bottom: 75px;
}

.travel {
	display: grid;
	counter-increment: sections;
	margin-bottom: clamp(40px, 4vw, 80px);
	grid-gap: 40px;
	gap: 40px;

	&__section {
		padding-left: 5rem;
	}

	&__means {
		display: none;
		align-items: center;
		gap: 25px;
		transform: translate(-4rem, 8%);

		@include min(1300px) {
			display: inline-flex;
		}
	}

	&__text {
		@include min(1600px) {
			transform: translateX(-5rem);
		}

		.travel__top {
			margin-bottom: rem(34px);
			padding-bottom: rem(34px);
			display: flex;
			//flex-wrap: wrap;
			align-items: flex-end;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: -2px;
				width: 150px;
				height: 4px;
				background-color: $color-secondary;
			}
		}
		.travel__heading {
			position: relative;
			color: $color-secondary;
			font-family: $font-body;
			font-size: rem(24px);
			font-weight: bold;
			display: flex;
			align-items: baseline;
			transform: translateX(-5rem);
			margin: 0 1em 0 0;

			@include min(1300px) {
				white-space: nowrap;
			}

			&::before {
				content: counter(sections, decimal-leading-zero);
				color: #cacaca;
				font-size: rem(50px);
				font-family: $font-headings;
				min-width: 5rem;
			}
		}

		.text-module {
			font-size: rem(18px);
			font-weight: 300;
			line-height: math.div(28, 18);

			p {
				line-height: inherit;
			}

			@include link {
				font-weight: 500;
				color: $color-brand;
				text-decoration: none;
			}
			@include link-over {
				text-decoration: underline;
			}
		}
	}

	@include min(800px) {
		&:nth-child(odd) {
			grid-template-areas: "text image";
			grid-template-columns: minmax(min-content, 400px) 1fr;
		}
		&:nth-child(even) {
			grid-template-areas: "image text";
			grid-template-columns: 1fr minmax(min-content, 400px);
		}
		&__text {
			grid-area: text;
		}
		&__flair {
			grid-area: image;
		}
	}

	@include min(1024px) {
		&:nth-child(odd) {
			grid-template-columns: minmax(min-content, 555px) 1fr;
		}
		&:nth-child(even) {
			grid-template-columns: 1fr minmax(min-content, 555px);
		}
	}

	&__flair {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		align-items: center;
		justify-content: center;
		@include min(800px) {
			&::before {
				content: "";
				display: block;
				aspect-ratio: 430 / 466;
				width: 100%;
				max-width: 320px;
				grid-column: 1;
				grid-row: 1;
				background: transparent url(/assets/images/travel-frame.svg)
					no-repeat 0 0 / 100% 100%;
				margin-left: auto;
				margin-right: auto;

				@include min($max-width) {
					max-width: 430px;
				}
			}
		}
	}

	&--first .travel__flair::before {
		@include min(1600px) {
			margin-left: 144px;
		}
	}
	&--second .travel__flair::before {
		@include min(1600px) {
			margin-left: 44px;
		}
	}
	&--third .travel__flair::before {
		@include min(1600px) {
			margin-left: 160px;
		}
	}

	&__picture {
		grid-column: 1;
		grid-row: 1;
		display: flex;
		justify-content: center;

		@include min(1600px) {
			display: inline;
		}

		img {
			object-fit: cover;
			object-position: center;
			mask-size: 100% 100%;
			mask-position: 0 0;
			mask-repeat: no-repeat;
			display: block;
		}
	}

	&--first .travel__picture img {
		mask-image: url(/assets/images/mask-travel-1.svg);
		@include min(1600px) {
			transform: translate(8%, 7%);
		}
	}
	&--second .travel__picture img {
		mask-image: url(/assets/images/mask-travel-2.svg);

		@include min(1600px) {
			transform: translate(0, -6%);
		}
	}
	&--third .travel__picture img {
		mask-image: url(/assets/images/mask-travel-3.svg);

		@include min(1600px) {
			transform: translate(1%, 4%);
		}
	}
}

.google-map {
	display: grid;
	&__iframe {
		max-width: 100%;
		width: 100%;
		height: 500px;
		border: 0;
	}
}

.about-page {
	display: grid;
	grid-gap: 50px;
	gap: 50px;

	@include min(800px) {
		grid-template-columns: 1fr 400px;
	}

	@include min(1280px) {
		grid-gap: 0 100px;
		gap: 0 100px;
		grid-template-columns: 1fr 560px;
	}

	&__heading {
		text-align: center;
		font-size: rem(34px);
		margin-top: 0.36em;
		margin-bottom: 0;
	}

	&__address {
		text-align: center;
		font-size: rem(21px);
		line-height: math.div(30, 21);
		margin-bottom: 65px;
	}

	&__gallery {
		@include min(800px) {
			grid-column: 1;
			grid-row: 2;
		}
	}

	&__booking {
		@include min(800px) {
			grid-column: 2;
			grid-row: 1 / span 2;
		}

		.booking {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__text {
		@include min(800px) {
			grid-column: 1;
			grid-row: 1;
		}

		.text-module {
			font-size: rem(18px);
			font-weight: 300;
			p {
				line-height: math.div(28, 18);
			}

			@include link {
				font-weight: 500;
				color: $color-brand;
				text-decoration: none;
			}
			@include link-over {
				text-decoration: underline;
			}
		}
	}
}

.pricing-page {
	display: grid;
	grid-gap: 50px;
	gap: 50px;

	@include min(800px) {
		grid-template-columns: 350px 1fr;
	}

	@include min($max-width) {
		grid-template-columns: 533px 1fr;
		grid-gap: 100px;
		gap: 100px;
	}

	&__cars {
		.text-module {
			p {
				line-height: math.div(28, 18);
				font-weight: 300;
				font-size: rem(18px);
			}
		}
	}

	&__heading {
		text-align: left;
		font-size: rem(34px);
		margin-top: 0.36em;
		margin-bottom: 0;
	}

	&__car-cta {
		color: $color-brand;
		font-weight: 600;
		font-size: rem(21px);
		margin-top: 2em;
	}

	&__text {
		.text-module {
			font-size: rem(18px);
			font-weight: 300;
			p {
				line-height: math.div(28, 18);
			}

			@include link {
				font-weight: 500;
				color: $color-brand;
				text-decoration: none;
			}
			@include link-over {
				text-decoration: underline;
			}
		}
	}
}

.cars {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 40px;
	gap: 40px;

	&__item {
	}

	&__image {
		aspect-ratio: 300 / 222;
		width: 100%;
		max-width: 300px;
		mask-image: url(/assets/images/car-mask.svg);
		mask-size: 100% 100%;
		mask-position: 0 0;
		mask-repeat: no-repeat;
		margin-bottom: 32px;
	}

	&__name {
		font-weight: bold;
		font-size: rem(32px);
		color: $color-brand;
		margin: 0 0 0.1em;
	}
	&__performance {
		font-weight: bold;
		font-size: rem(24px);
		color: $color-secondary;
		margin: 0 0 0.3em;
	}
	&__transmission {
		font-weight: 500;
		font-size: rem(19px);
		color: #7a7a7a;
		margin: 0;
	}
}

.pricing {
	margin: 0 0 3.5rem;
	padding: 0;
	list-style-type: none;

	&__section {
		border-bottom: 4px $color-secondary solid;
		padding-bottom: 3.5rem;

		&:not(:last-child) {
			margin-bottom: 3.5rem;
		}
	}

	&__price {
		color: #7a7a7a;
		font-size: rem(19px);
		font-weight: 500;
		margin: 0 0 1em;

		strong {
			color: $color-brand;
			font-family: $font-headings;
			font-size: rem(40px);
			font-weight: normal;
		}
	}

	&__type {
		font-weight: bold;
		font-size: rem(24px);
		color: $color-secondary;
		margin: 0 0 0.5em;
	}

	&__timetable {
		margin: 0;
	}
}

.thank-you {
	text-align: center;
	.text-module {
		text-align: inherit;
		p {
			font-size: rem(20px);
			text-align: inherit;
		}

		@include link {
			color: $color-brand;
			text-decoration: underline;
		}
		@include link-over {
			text-decoration: none;
		}
	}
}
